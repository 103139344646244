import { forwardRef } from 'react';

import type { TVComponentWithElementProps } from '../../../../style-system/models';
import { useFieldContext } from '../context';

import { fieldOrientationStyles } from './field-orientation.styles';

type FieldOrientationProps = TVComponentWithElementProps<'span', typeof fieldOrientationStyles>;

const FieldOrientation = forwardRef<HTMLSpanElement, FieldOrientationProps>(function FieldOrientation(
    { children, className, ...rest },
    ref,
) {
    const { getOrientationProps } = useFieldContext();
    const { orientation, ...domProps } = getOrientationProps(rest, ref);

    return (
        <span
            className={fieldOrientationStyles({
                direction: orientation?.direction,
                reverse: orientation?.reverse,
                className,
            })}
            {...domProps}
        >
            {children}
        </span>
    );
});

export type { FieldOrientationProps };
export { FieldOrientation };
